<template>
  <div class="calcu-wrap">
    <div class="calcu-bar">
      <span @click="busnessClick" :class="{ 'active': active === 'busness' }">
        <router-link class="link-font" to="/">商业贷款</router-link>
      </span>
      <span @click="pubaccuClick" :class="{ 'active': active === 'pubaccu' }" >
        <router-link class="link-font" to="/pubaccu">公积金贷款</router-link>
      </span>
      <span @click="compositionClick" :class="{ 'active': active === 'composition' }">
        <router-link class="link-font" to="/composition">组合贷款</router-link>
      </span>
    </div>
    <!-- 贷款计算器子路由 -->
    <router-view />
  </div>
  
</template>

<script>
import $http from '../utils/request'

export default {
  created() {
    // 初始化定位信息
    if(this.$route.query.cityid && this.$route.query.cityname) {
      this.$store.commit('setLocation', { cityId: this.$route.query.cityid, cityName: this.$route.query.cityname });
    }
    // 初始化用户id
    if(this.$route.query.userid) {
      this.$store.commit('setUserId', this.$route.query.userid);
    }
    $http({
      url: `/appapi/normals/${this.$store.state.location.cityId}/v1/loancalculation`,
      method: 'get'
    }).then(res => {
      const first_default = `${res.data.house_price.payment_percent.first_default}%`;
      const second_default = `${res.data.house_price.payment_percent.second_default}%`;
      const max_fund_loan_value = res.data.max_fund_loan_value;
      if(res.data) {
        this.$store.commit('setDownPayment', { firstDefault: first_default, secondDefault: second_default })
        this.$store.commit('setFirstMaxFundLoan', max_fund_loan_value.first);
        this.$store.commit('setSecondMaxFundLoan', max_fund_loan_value.second);
        // 存储有关利率的字典
        const com_rate = res.data.loan_price.com_loan.loan_rate;
        const com_rate_1 = res.data.loan_price.com_loan.loan_rate_1;
        const fund_rate = res.data.loan_price.fund_loan.loan_rate;
        const fund_rate_5 = res.data.loan_price.fund_loan.loan_rate_5;
        this.$store.commit('setRate', { com_rate, com_rate_1, fund_rate, fund_rate_5 })
        let loanTimeObj = res.data.house_price.com_loan.loan_time;
        let loanTimeList = Object.keys(loanTimeObj).map(item => loanTimeObj[item])
        // 存储有关贷款年限的字典
        this.$store.commit('setLoanTime', loanTimeList);
      }
    })
  },
  methods: {
    busnessClick() {
      this.active = 'busness';
      window.gio('track', 'loanclick', {
        "module_var": "商业贷款",
        "buttonname_var": "商业贷款",
        "userid_var": this.$store.state.userId
      });
    },
    pubaccuClick() {
      this.active = 'pubaccu';
      window.gio('track', 'loanclick', {
        "module_var": "公积金贷款",
        "buttonname_var": "公积金贷款",
        "userid_var": this.$store.state.userId
      });
    },
    compositionClick() {
      this.active = 'composition';
      window.gio('track', 'loanclick', {
        "module_var": "组合贷款",
        "buttonname_var": "组合贷款",
        "userid_var": this.$store.state.userId
      });
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].style.background = '#F4F4F4'
  },
  computed: {
    active: {
      get() {
        return this.$route.name;
      },
      set() {
        // 不加会报警告
      }
    }
  }
}
</script>

<style scoped>
.calcu-bar .active {
  font-weight: 600;
  background-image: url('../assets/image/active.png');
  background-size: 68px 20px;
  background-repeat: no-repeat;
  background-position: 50% 50px;
}
.calcu-wrap {
  background-color: #F4F4F4;
}
.calcu-bar {
  background: #ffffff;
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.calcu-bar span {
  line-height: 88px;
  font-size: 28px;
}
.calcu-bar .link-font {
  color: #333;
  font-weight: bold;
  font-size: 32px;
}
.calcu-bar .active .link-font {
  color: #333;
}
</style>